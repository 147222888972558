var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.transactions,"dense":""},scopedSlots:_vm._u([{key:"item.package",fn:function(ref){
var item = ref.item;
return [_c('div',{key:item.id,staticClass:"text-start"},[_vm._v(" "+_vm._s(item.module.name)+" ")])]}},{key:"item.amountPaid",fn:function(ref){
var item = ref.item;
return [_c('div',{key:item.id,staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.formatCurrency(item.amountPaid))+" ")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [(item.plan)?_c('div',{key:item.id,staticClass:"text-center",class:_vm.dateIsAfter(_vm.addDaysToDate({ date: item.date, days: item.plan.duration})) ? '' : 'error--text'},[_vm._v(" "+_vm._s(_vm.dateFormat(_vm.addDaysToDate({ date: item.date, days: item.plan.duration})))+" ")]):_vm._e()]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('div',{key:item.id,staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.formatCurrency(item.value))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{key:item.id,staticClass:"d-flex justify-center align-center text-center"},[_c('v-switch',{staticClass:"mt-1",attrs:{"error":!item.status,"success":item.status,"dense":"","hide-details":""},on:{"change":function($event){return _vm.updateTransactionStatus(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{key:item.id,staticClass:"text-right"},[_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","x-small":"","height":"30px","dark":""},on:{"click":function($event){return _vm.openPaymentDialog(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"error","x-small":"","height":"30px","dark":""},on:{"click":function($event){return _vm.openConfirmeDialog(item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)],1)]}}],null,true)}),_c('confirm-dialog',{attrs:{"dialog":_vm.confirm,"id":_vm.removeId,"question":"Pretende remover a transação?"},on:{"yes":function($event){return _vm.deleteTransaction()},"no":function($event){_vm.confirm = false}}}),_c('error-dialog',{attrs:{"dialog":_vm.showError,"message":_vm.error},on:{"close":function($event){_vm.showError = false}}}),_c('progress-dialog',{attrs:{"processing":_vm.isLoading}}),_c('success-dialog',{attrs:{"dialog":_vm.showSuccess,"message":_vm.success},on:{"close":function($event){_vm.showSuccess = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }