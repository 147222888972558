<template>
  <div class="fill-heigt" fluid>
    <v-row>
      <v-col cols="11" md="8">
        <!-- <div class="text-left subtitle" style="margin-top:3%">
          Painel de Clientes 
        </div> -->
      </v-col>
      
      <v-col cols="12" md="4">
        <div class="text-right">
          <v-text-field
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-card v-if="$apollo.queries.transactions.loading">
          <v-skeleton-loader
            :type="
              `table-thead, table-row-divider@5, table-tfoot`
            "
          ></v-skeleton-loader>
        </v-card>
        <v-card v-else class="px-4">
          <v-card-title>
            Tabela geral de pagamentos
          </v-card-title>
          <finance-table :transactions="transactions" @remove="removeTransaction" />
          <PaymnetDialog /> 
        </v-card>
      </v-col>  
    </v-row> 
  </div>
</template>

<script>
import { GET_ENTITY_TRANSACTIONS_QUERY } from '../graphql/Query';
import FinanceTable from '../components/FinanceTable.vue';
import PaymnetDialog from '../components/PaymentDialog.vue';
export default {
  name: 'Finance',
  components: {
    FinanceTable,
    PaymnetDialog
  },
  data: () => ({
    transactions: [],
  }),
  apollo: {
    transactions: {
      query: GET_ENTITY_TRANSACTIONS_QUERY,
      variables () {
        return { entityId: this.$route.params.id }
      }
    }
  },
  created () {
    // eslint-disable-next-line no-undef
    Fire.$on('updateTransaction', (transaction) => {
      const index = this.transactions.findIndex(findTransaction => findTransaction.id === transaction.id);
      this.transactions.splice(index, 1, transaction)
      console.log("Nothing wrong!");
    })

    // eslint-disable-next-line no-undef
    Fire.$on('remove', (id) => {
      const index = this.transactions.findIndex(findTransaction => findTransaction.id === id);
      this.transactions.splice(index, 1)
    })
  },

  methods: {
    removeTransaction(id) {
      const index = this.transactions.findIndex(findTransaction => findTransaction.id === id);
      this.transactions.splice(index, 1)
    }
  }
}
</script>