
import { dateIsAfter, distanceBetweenDatesInDays } from '../utils';

export default {
  methods: {
    dateIsAfter (value) {
      return value ? dateIsAfter(value) : false;
    },
    distanceBetweenDatesInDays ({ date, days }) {
      return date && days ? distanceBetweenDatesInDays({date, days}) : null;
    }
  }
}
  